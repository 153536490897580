.store{
    min-height:100vh;
    width: 100%;
    z-index: -1;
    /* padding: 8rem 0 2rem 0; */
 } /*   */
.dx{
    background-image:  url('./cube.png'), url('./spring.png');
    background-repeat: no-repeat,no-repeat;
    background-position: bottom left, top right ;
    z-index: 11;
    height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.mx{
    background-image:  url('./spring.png');
    background-repeat: no-repeat;
    background-position: top right ;
    z-index: 11;
    height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.top{
    background: #1E2947;
}
.f{
    position: absolute;
    left: 20%;
}
.dwn{
    background: #F2E8FF;
    min-height: 70vh ;
    width: 100vw;
    /* padding-top: 9rem; */
    /* display: grid;
    grid-template-columns: repeat(4,250px);
    grid-gap: 3rem;
    align-items: center;
    justify-content: center; */
    background: #F2E8FF;
    /* display: grid;
    grid-template-columns: repeat(auto-fit, minmax(305px, 1fr)); */
    /* padding: 2rem 2rem 2rem 4rem; */
    margin: auto;
    display: block;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.GridStyle{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    place-items: center;   
}
.base{
    min-height: 100%;
    width: 100vw;
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: space-evenly;
    /* background:  linear-gradient( #1E2947 50%, #8648E5 50%); */
    padding: 8rem 0 2rem 0;
}
.PBB{
    display: block;
    text-align: center;
    padding-top: 1rem;
}
.PB{
    display: block;
    text-align: center;
}

.MBB{
    display: none;
}
.MB{
    display: none;
}
@media  screen and (max-width: 760px){
    .PBB{
        display: none;
    }
    .PB{
        display: none;
    }
    .PKMAB{
        display: block;
        text-align: center;
        padding: 1rem;
    }
    .MB{
        display: block;
        text-align: center;
        padding: 10px;
    }
    .base{
        padding: auto;
    }
}

.what{
    background: #1E2947;
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 8rem 0 2rem 0;
 }
/* .base{
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: space-evenly;
    background:  linear-gradient( #1E2947, #8648E5);
}
.PBB{
    display: block;
    text-align: center;
    padding-top: 1rem;
} */
.PB{
    display: block;
    text-align: center;
    margin-top: 2rem;
}

/* .MBB{
    display: none;
} */
.MB{
    display: none;
}
@media  screen and (max-width: 760px){
    /* .PBB{
        display: none;
    } */
    .PB{
        display: none;
    }
    /* .PKMAB{
        display: block;
        text-align: center;
        padding: 1rem;
    } */
    .MB{
        display: block;
        text-align: center;
        padding: 2rem;
        margin-top: 1rem;
    }
}

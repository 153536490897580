@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@600&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: 'Oswald', sans-serif; */
  /* font-family: 'Work Sans', sans-serif; */
}

h1 {
  color:#fff;
  font-family: 'Oswald', sans-serif;
  font-size: 4rem;
}

h2 {
  color:#fff;
  font-family: 'Oswald', sans-serif;
  font-size: 3rem;
}
span{
  color:#fff;
  font-family: 'Work Sans', sans-serif;
    font-size: 1rem;
}
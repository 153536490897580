.poly{
    min-height: 100vh;
    width: 100%;
    /* background: #F2E8FF; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8rem 0 2rem 0;
}
.HONDA{
    display: none;
}

@media (max-width: 760px) {
    .HONDA{
        display: block;
        padding: 1rem;
    }
    .TVS{
        display: none;
    }
    .poly{
        padding: 2rem 0;
    }
    
}

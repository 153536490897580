 /* Navigation Bar */
         .nav{
             /* background: red; */
          /*  border: 2px solid rgba(243, 186, 47, 0.15); */
            display: flex;
            justify-content: space-between;
            align-items:center;
            height: 7rem; width: 100%;
            /* padding-left: 6rem; */
            /* box-shadow: rgba(243, 186, 47, 0.15); */
            position: fixed;
            /* margin-top: 3rem; */
            /* font-family: 'Oswald', sans-serif !important ; */
            z-index: 11111;
            /* background: cornsilk; */
            transition:  0.5s linear;
        }
        .navActive{
            display: flex;
            justify-content: space-between;
            align-items:center;
            height: 4rem; width: 100%;
            /* padding-left: 6rem; */
            /* box-shadow: rgba(243, 186, 47, 0.15); */
            position: fixed;
            /* margin-top: 3rem; */
            /* font-family: 'Oswald', sans-serif !important ; */
            background:	white;
            padding-top: 1rem;
            transition:  0.5s linear;
            /* transition-duration: 1s; */
        }
        .Logo{
            /* background: brown; */
            margin-left: 6rem;
            width: 15rem;
            padding-bottom: 1rem;
            transition:  0.5s linear;
        }
        .LogoB{
            margin-left: 6rem;
            width: 10rem;
            padding-bottom: 1rem;
            transition:  0.5s linear;
        }
        .list{
            list-style: none;
            display: flex;
            justify-content: space-between;
            /* background: aqua; */
            /* width: 60%; */
            text-decoration: none;
            align-items: center;
            /* margin-right: 10rem; */
            margin-right: 4rem;
            width: 50rem;  
        }
        /* .Hbtna{
           background: chartreuse;
            width: 220px;
            display: flex;
            justify-content: space-between;
        }
        .Nbtn1{
            color: #FFFFFF;
            background: #00151E;
            border: 1.5px solid #F3BA2F;
            height: 30px; width: 100px;
            cursor: pointer;
        }
        .Nbtn2{
            height: 30px; width: 100px;
            background: #F3BA2F;
            border: 1.5px solid #F3BA2F;
            cursor: pointer;
            color: #00151E;
            font-weight: 700;
        } */
        .NLi{
            text-decoration: none;
            color: #8D4FEC;
            /* display: block ruby; */
            /* font-family: 'Oswald', sans-serif !important ; */
        }
        .NLi > li{
            /* margin-top: 21%; */
            /* text-decoration: none;
            color: #8D4FEC; */
            /* display: block ruby; */
            /* font-family: 'Oswald', sans-serif !important ; */
        }
        /* .none{
            width: 42rem;
            display: flex;
            justify-content: space-between;
        } */
        .container{
            display: none;
        }
        .hamburger{
            display: none;
        }
       
        @media(max-width: 768px){
            .nav{
                justify-content: space-between;
                /* padding-left: 2rem; */
                height: 7rem;
            }
            .navActive{
                justify-content: space-between;
                /* padding-left: 2rem; */
                height: 4rem;
                background:#F8F8F8;
                padding-top: 0;
            }
            .none{
                display: none;
            }
            .hamburger{
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                /* align-items: center; */
                height: 3rem; width: 3.5rem;
                margin-right: 2rem;
                cursor: pointer;
                background:#8648e5;
                border-radius: 5px;
                padding-left: 0.7rem;;
                
            }
            .Line1{
                height: 0.00rem;
                width: 2rem;
                border: 0.5px solid #FFFFFF;
                background: #FFFFFF;
                border-radius: 0.5rem;
                transition: 1.5s;
                margin-top: 0.5rem;
            }
            .Line2{
                height: 0.00rem;
                width: 2rem;
                border: 0.5px solid #FFFFFF;
                background: #FFFFFF;
                border-radius: 0.5rem;
                transition: 1.5s;
            }
            .Line3{
                height: 0.00rem;
                width: 1.4rem;
                border: 0.5px solid #FFFFFF;
                background: #FFFFFF;
                border-radius: 0.5rem;
                transition: 1.5s;
                margin-bottom: 0.5rem;
            }
            .list{
                flex-direction: column;
                 align-items:flex-start; 
                 height: auto;
            }
            .container{
                display: flex;
                right: 0;
                left: 40%;
                top: 0rem;
                /* transition: all 2s ease-out ; */
                /* transition-property: transform, opacity, -webkit-transform; */
                /* transition-duration: 0.4s, 0.3s, 0.4s;
                transition-timing-function: ease, ease, ease;
                transition-delay: 0s, 0s, 0s; */
                flex-direction: column;
                position: absolute;
                width: 100%;
                height: 100vh;
                padding-top: 2rem;
                padding-left: 1rem;
                background: #1E2947f4;
                /* border-radius: 1rem; */
                
            }
            /* .Hbtna{
                right:50%;
                padding-top: 1rem;
            } */
            .Logo{
                margin-left: 2rem;
                width: 11rem;
                padding-top: 1rem;
            }
            .LogoB{
                margin-left: 2rem;
                width: 11rem;
                padding-top: 1rem;
            }
        }
                               /* Navigartion Bar Ends */
.supply{
    background: #F2E8FF;
    min-height: 100vh;
    width: 100%;
    position: relative;
    text-align: center;
    z-index: -1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8rem 0 2rem 0;
}
.center{
    margin-top: 4rem;
    margin-bottom: 4rem;
}
.imageDiv{
    background-image: url(./image1.png),url(./image2.png),url(./image3.png), url(./image4.png);
    background-repeat: no-repeat;
    background-position: top left, top right, bottom left, bottom right;
    background-size: 100px,100px,150px,100px;
    margin: 5px;
}
.img{
    display: block;
}
@media(max-width: 768px){
    .center{
        width: 90%;
    }
    .img{
        display: none;
    }
}
.mon{
    min-height: 100vh;
    width: 100%;
    z-index: -1;
    /* background-image: url("./image10.png");
    background-repeat: no-repeat;
    background-size: 100% auto; */
    /* background-position-y: -30%; */
    background-color: #F2E8FF;
}
.u{
    height: 40vh;
    width: 100%;
    /* background: #8648E5; */
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    /* padding: 8rem 0 2rem 0; */
}    
.gg{
   position: absolute;
   left: 10%;
   /* margin-right: 70rem; */
}
.down{
    background: #F2E8FF;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    padding-bottom: 50px;
    place-items: center;
    padding: 2rem 10%;
    align-self: center;
    grid-gap: 2rem;
}
/* .GridS{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    place-items: center; 
    width: 100%;
    background:red; 
} */
@media screen and (max-width: 760px){
    .gg{
        /* margin-left: 0; */
        position: static;
    }
    .mon{
        background-image: url("./image11.png");
        background-repeat: no-repeat;
        background-size: 100% 20%;
    }
    .down{
        
        padding: 1rem;
    }

}
.Box{
    width: 35rem;
    min-height: 15rem;
    background: #F2E8FF;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 16px;
    flex-direction: row;
    box-shadow: 5px 5px 10px;
    box-shadow: inset 0 0 10px;
    display:block;
    margin:auto;
}
.pc{
    margin-left: 10rem;
    margin-top: 0rem;
}

@media (max-width: 768px){
    .Box{
        width: 15rem;
        flex-direction: column;
    }
    .pc{
        /* margin-top: 1rem; */
        margin-left: 0rem;
    }
}
.opn{
    background: linear-gradient(70.52deg, #1E2947 54.45%, #8648E5 176.68%);
}
/* .Left{

}
.Right{

} */
.anam{
    background-image: url('./left.png'),url('./bottom.png'),url('./cube.png');
    background-repeat: no-repeat,no-repeat,no-repeat;
    background-position: top left, bottom left, bottom center;
    z-index: 1;
    /* width: 100%; */
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    padding: 8rem 0 2rem 0;
}
@media screen and (max-width: 760px){
    .anam{
        flex-direction: column;
        justify-content: space-evenly;
        background-image: url('./left.png'),url('./bottom.png');
        background-repeat: no-repeat,no-repeat;
        background-position: top left, bottom left;
        z-index: 1;
        /* width: 100%; */
        width: 100%;
        min-height: 100vh;
        display: flex;
        align-items: center;
        padding: 8rem 0 2rem 0;
    }
    .Left{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 12rem;
    }
    .Right{
        display: flex;
        align-items: center;
        justify-content: center;
    }
} 
/* body{
    background-size: 100% 100vh;
    background-repeat: no-repeat;
} */

.mainBg {
    background-image: url("./background1.png");
    /* background-position: center; */
    background-repeat: no-repeat;
    background-size: 100% 100vh;
    background-color: #00008B;
}
.Home{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
    text-align: left;
    padding: 8rem 0 2rem 0;
}
/* .head{
    color: white;
    font-size: 5em;
} */
 .Hpara{
    display: block;
    text-align: center;
    margin-top: 1rem;
}
 /* .Hbtn{
    border-radius: 2rem;
    width: 13rem;
    height: 4rem;
    background:  #8D4FEC;
    border: 1px solid  #8D4FEC;
    cursor: pointer;
    color: white;
    font-size: 1.2rem;
    margin-top: 2rem;
} */
.Mpara{
    display: none;
}
.PHH{
    display: block;
}
.MHH{
    display: none;
}
/* @media (max-width: 1200px){
    .mainBg {
        background: url("./background1.png");
        
        background-repeat: no-repeat;
        background-size: auto 100vh;
    }
    } */
@media (max-width: 768px){
    .head{
        font-size: 2rem ;
        font-size: 1.5rem;
        text-align: center;
        margin-top: 1rem;
    }
    .Mpara{
        color: white;
        text-align: center;
        margin-top: 1rem;
        display: block;
        padding: 0 1rem 0 1rem;
    }
    .Hpara{
        display: none;
    }
    .heading{
        font-size: 1rem;
    }
    .Home{
        text-align: center;
        padding: 8rem 0 2rem 0;
    }
    /* .PHH{
        display: none;
    } */
    .MHH{
        display: block;
    }
    .mainBg {
        background: url("./background1.png");
        /* background-position: center; */
        background-repeat: no-repeat;
        background-size: auto 100vh;
    }
}

.road{
    background: #1E2947;
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem 0 8rem 0;
    display: grid;
    place-items: center;
}
.one{
    /* height: 14rem; */
    width: 40rem;
    background: #F2E8FF;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    box-shadow: 0 4 4 0 rgb(0, 0, 0, .25);
    /* flex-direction: row; */
}
.three{
    width: 40rem;
    background: #F2E8FF;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    box-shadow: 0 4 4 0 rgb(0, 0, 0, .25);
    margin-top: -10px;
    
}
.two{
    /* height: 14rem; */
    width: 40rem;
    background: #F2E8FF;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    /* box-shadow: ; */
    margin-left: 13rem;
    margin-top: -10px;

}

@media screen and (max-width: 760px) {
    .two{
        margin-left: 0;
    }
    .one{
        width: 80%;
        /* display: block; */
        margin: auto;
        margin-top: 20px;
        text-align: center;
        /* flex-direction: column; */
        /* height: 16rem; */
        display: flex;
        flex-direction: column;
        /* padding: 1rem; */
        /* background: brown; */
        max-height: 30rem;
        box-shadow:0px 20px 12px 0px rgba(0,0,0,0.25);
    }
    .three{
        width: 80%;
        display: block;
        margin: auto;
        margin-top: 20px;
        text-align: center;

    }
    .two{
        width: 80%;
        display: block;
        margin: auto;
        margin-top: 20px;
        text-align: center;

    }
    .LL{
        /* background: red; */
        width: 100%;
    }
    .road{
        padding: 2rem 0 ;
    }
}
.dev{
    background:  #8D4FEC    ; /* #7D3EDE; */
    /* height: 100vh; */
    width: 100%;
    /* background-image: url('./back.png'); */
    background-repeat: no-repeat;
    /* background-size: 100% 100%; */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* flex-direction: column; */
    padding: 8rem 0 2rem 0;
}
.box{
    background: #9356F1;
    min-height: 20rem;
    width: 60rem;
    border-radius: 8px;
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.ib{
    width: 35rem;
    height: 4rem;
    background: white ;
    display: flex;
    border-radius: 2rem;
    align-items: center;
    flex-direction: row;
}
.in{
    width: 100%;
    min-height: 4rem;
    border-radius: 2rem;
    /* border: 1.5px solid #9356F1; */
    outline: none;
    border: none;
}
.btn{
    /* position: relative; */
    padding-right: 0.5rem;
    
}
/* .buttonIn {
    width: 300px;
    position: relative;
}
  
.enter {
    margin: 0px;
    padding: 0px;
    width: 100%;
    outline: none;
    height: 30px;
    border-radius: 5px;
}
  
.clear {
    position: absolute;
    top: 0;
    border-radius: 5px;
    right: 0px;
    z-index: 2;
    border: none;
    top: 2px;
    height: 30px;
    cursor: pointer;
    color: white;
    background-color: #1e90ff;
    transform: translateX(2px);
} */
@media (max-width: 768px){
    .box{
        width: 90%;
        height: 22rem;
    }
    .ib{
        /* flex-direction: column; */
        width: 90%;
        flex-direction: column;
    }
    .dev{
        background-image: none;
        padding: 8rem 0 2rem 0;
    }
    .btn{
        margin-top: 0.5rem;
    }
}
/* .sec{
    background: #1E2947;
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10rem 0 2rem 0;
    z-index: 10000000000000;
    position: fixed;
    top: 0;overflow: auto;
} */

@media screen and (max-width:600px) {
    .sec{
        background: #1E2947;
        max-height: 100vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 11rem 0 3rem 0;
        z-index: 10000000000000;
        position: fixed;
        top: 0;
        left: 0;
        overflow: auto;
    }
}

@media screen and (min-width:600px) {
    .sec{
        /* background: #1E2947; */
        max-height: 100vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10rem 0 3rem 0;
        z-index: 10000000000000;
        position: fixed;
        top: 0;
        left: 0;
        overflow: auto;
    }
}